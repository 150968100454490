export const service = [
    { 
        id: 1, 
        title: "Preparing Complete Applications", 
        src: "images/applications.svg" 
    },
    { 
        id: 2,
        title: "Dedicated Prepare Consultant ", 
        src: "images/consultant.svg" 
    },
    { 
        id: 3, 
        title: "Translators Available", 
        src: "images/translators.svg" 
    },
    { 
        id: 4, 
        title: "Accuracy Guaranteed", 
        src: "images/accuracy.svg" 
    },
  
]